
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton } from '@ionic/vue';
// import ExploreContainer from '@/components/ExploreContainer.vue';
import { LocalNotifications } from '@capacitor/local-notifications';


export default  {
  name: 'TheGospelTab',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButton },
  methods: {
    createLocalNotification() {
      LocalNotifications.schedule({
        notifications: [
          {
            title: "Title",
            body: "Body",
            id: 1,
            schedule: { at: new Date(Date.now() + 1000) }
          }
        ]
      });
    }
  }
  
}
